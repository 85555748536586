<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['inputTable'].refresh()"
    >
      <template #table>
        <b-table
          ref="inputTable"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          responsive
          :items="getInventoryInput"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(quantity)="data">
            <div class="d-flex justify-content-center">
              <span class="text-success">+ {{ data.item.quantity }}</span>
            </div>
          </template>
          <template #cell(desc_type_motive)="data">
            <b-badge
              class="text-center w-100"
              pill
              :variant="colorStatusNote(data.item.type_id)"
            >
              <span>{{ data.item.desc_type_motive }}</span>
            </b-badge>
          </template>
          <template #cell(created_at)="data">
            <span
              >{{ data.item.created_by }} <br />
              {{ data.item.entry_at | myGlobalDay }}</span
            >
          </template>
          <template #cell(created_at)="data">
            <span>{{ data.item.created_by }}</span>
            <br>
            <span>{{ data.item.entry_at | myGlobalWithHour }}</span>
          </template>
          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
              <feather-icon
                v-if="data.item.is_detailed == 1 || data.item.item_detail_id != null"
                class="mr-1 cursor-pointer text-primary"
                icon="EyeIcon"
                title="View detail"
                size="17"
                @click="openDetailedInputModal(data.item)"
              />
              <span v-else>No detail</span>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <detailed-input-modal
      v-if="detailedInputModalOn"
      :purchase-order-id="purchaseOrderId"
      :item-id="itemId"
      :item-detail-id-from-table="itemDetailIdFromTable"
      @close="closeDetailedInputModal"
    />
  </div>
</template>

<script>
import InventoryService from '@/views/logistic/views/inventory2/services/logistic-inventory.service';
import InputInventoryFilters from "@/views/logistic/views/inventory2/data/input-inventory.data.filters.js"
import DetailedInputModal from "@/views/logistic/views/inventory2/modals/DetailedInputModal.vue"

export default {
  components: {
    DetailedInputModal,
  },
  props: {
    itemInputOutputId: { // itemId
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      filters: InputInventoryFilters,
      fields: [
        {
          key: "purchase_order",
          label: "Bill code",
        },
        {
          key: "item_name",
          label: "Item",
        },
        {
          key: "category_name",
          label: "Category",
        },
        {
          key: "subcategory_name",
          label: "Subcategory",
        },
        {
          key: "quantity",
          label: "Quantity",
          thStyle:{
            textAlign:'center'
          },
        },
        {
          key: "created_at",
          label: "Created By",
        },
        {
          key: "actions",
          label: "Detail",
          thStyle: {
            textAlign:'center'
          },
        },
      ],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      selectedItem: [],
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: "created_at",
      sortDesc: true,
      searchInput: "",
      items: [],
      motiveToSend: {},
      mood: 0,
      motiveLossTypes: [],
      detailedInputModalOn: false,
      purchaseOrderId: null,
      itemId: null,
      itemDetailIdFromTable: null,
    };
  },
  async created() {
    await this.getCategories();
    await this.getSubcategories();
  },
	computed: {
	  moduleId() {
	    return this.$route.matched[0].meta.module;
		},
    filterCategoryId() {
	    return this.filters[2].model;
		}
  },
  watch: {
    filterCategoryId() {
      this.getSubcategories();
    }
  },
  methods: {
    async getCategories() {
      try {
        const data = await InventoryService.getItemsCategory();
        if (data.status === 200) {
          this.filters[2].options = data.data
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    closeDetailedInputModal() {
      this.detailedInputModalOn = false;
    },
    openDetailedInputModal(item) {
      this.purchaseOrderId = item.purchase_order_id;
      this.itemId = item.item_id;
      this.itemDetailIdFromTable = item.item_detail_id;
      this.detailedInputModalOn = true;
    },
    async getSubcategories() {
      try {
        const data = await InventoryService.getItemsSubcategory({category_id: this.filters[2].model});
        if (data.status === 200) {
          this.filters[3].options = data.data
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    colorStatusNote(status) {
      switch (status) {
        case 1:
          return "light-success";
        case 2:
          return "light-info";
        case 3:
          return "light-warning";
        case 4:
          return "light-primary";
        default:
          return "light-secondary";
      }
    },
    async getInventoryInput(ctx) {
      try {
        const data = await InventoryService.getInventoryInput({
          perpage: ctx.perPage,
          orderby: 1,
          page: ctx.currentPage,
          order: "asc",
          text: this.filterPrincipal.model,
          dateFrom: this.filters[0].model,
          dateTo: this.filters[1].model,
          categoryId: this.filters[2].model,
          subcategoryId: this.filters[3].model,
          itemId: this.itemInputOutputId,
        });
        this.items = data.data.data;
        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;
        return this.items || [];
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  },
};

</script>

<style>
</style>
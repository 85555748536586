export default [
    {
      type: 'select',
      label: 'Brand',
      margin: true,
      showLabel: true,
      options: [
        { 'id': null, 'name': 'All' },
      ],
      model: null,
      reduce: 'id',
      selectText: 'name',
      cols: 12,
      md: 2,
      visible: true,
    },
    {
      type: 'select',
      label: 'Condition',
      margin: true,
      showLabel: true,
      options: [
        { value: null, label: 'All' },
        { value: 1, label: 'New' },
        { value: 2, label: 'Used' },
        { value: 3, label: 'Repaired' },
      ],
      model: null,
      reduce: 'value',
      selectText: 'label',
      cols: 12,
      md: 2,
      visible: true,
    },
  ];
<template>
  <div>
    <b-modal
      v-model="showModal"
      size="lg"
      :title="disabledAll ? 'Watch' : 'Edit'"
      @hidden="closeModal"
      :hide-footer="disabledAll"
      title-tag="h3"
    >
      <ValidationObserver ref="form">
        <div>
          <b-row>
            <!-- Price -->
            <b-col sm="3">
              <ValidationProvider
                v-slot="{ errors }"
                rules="validate-amount"
              >
                <b-form-group label="Price">
                  <money
                    v-bind="vMoney"
                    v-model="price"
                    :prefix="currency+' '"
                    :state="false"
                    :disabled="disabledAll"
                    class="form-control custom-specified-column"
                    :class="
                      errors[0] ? 'border-danger' : ''
                    "
                  />
                  <span v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}</span
                  >
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <!-- Exchange -->
            <b-col sm="3">
              <b-form-group label="Exchange Rate">
                <money
                  v-bind="vMoney"
                  v-model="exchange"
                  :prefix="'S/. '"
                  :state="false"
                  disabled
                  class="form-control custom-specified-column"
                />
              </b-form-group>
            </b-col>

            <!-- Lifetime -->
            <b-col sm="6">
              <ValidationProvider
                rules="required|greated-than-zero"
                v-slot="{ errors }"
              >
                <b-form-group label="Life Time (Months)">
                  <b-form-input
                    v-model="lifeTimeValue"
                    :disabled="disabledAll"
                    class="input-background-white"
                    type="number"
                    :class="{ 'border border-danger': errors[0] }"
                  ></b-form-input>
                  <span v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}</span
                  >
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <!-- Salvage Value -->
            <b-col sm="6">
              <ValidationProvider
                rules="required|greated-than-zero"
                v-slot="{ errors }"
              >
                <b-form-group label="Salvage Value">
                  <money
                    v-bind="vMoney2"
                    v-model="salvageValue"
                    :prefix="currency + ' '"
                    :state="false"
                    :disabled="disabledAll"
                    class="form-control custom-specified-column w-100"
                    :class="
                      errors[0] ? 'border-danger' : ''
                    "
                  />
                  <span v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}</span
                  >
                </b-form-group>
              </ValidationProvider>
            </b-col>

             <!-- Salvage Value -->
            <b-col sm="6" v-for="item in specifications" :key="item.id">
                <b-form-group :label="itemName(item.name)">
                  <b-form-input
                    v-model="item.value"
                    :disabled="disabledAll"
                    class="input-background-white"
                  ></b-form-input>
                </b-form-group>
            </b-col>
          </b-row>
        </div>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <!-- Footer: Left Content -->
        <b-button variant="danger" class="px-1" @click="cancel()"
          >CANCEL</b-button
        >
        <b-button variant="primary" class="px-1" @click="saveChanges()"
          >SAVE</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import InventoryService from "@/views/logistic/views/inventory2/services/logistic-inventory.service";
import VMoney from "v-money";
import { mapActions } from "vuex";
export default {
  props: {
    itemDetailId: {
      type: Number,
      required: true,
    },
    disabledAll: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    VMoney,
  },
  directives: {
    money: VMoney,
  },
  data() {
    return {
      showModal: false,
      salvageValue: 0,
      lifeTimeValue: 0,
      specifications: [],
      price: 0,
      currency: "",
      exchange: 0,
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      vMoney2: {
        decimal: ",",
        thousand: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        min: 0.0,
        masked: false,
      },
    };
  },
  async created() {
    this.getItemDetails();
    this.showModal = true;
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions({}),
    closeModal() {
      this.$emit("closeModal");
    },
    async saveChanges() {
      const validation = await this.$refs.form.validate();
      if (!validation) return;
      const { value } = await this.showConfirmSwal();
      if (!value) return;
      const params = {
        item_detail_id: this.itemDetailId,
        life_time: this.lifeTimeValue,
        salvage_value: this.salvageValue,
        price: this.price,
        specifications: this.specifications,
      };
      this.addPreloader();
      try {
        const data = await InventoryService.updateValuesInItemDetail(params);
        if (data.status == 200) {
          this.showSuccessSwal();
          this.refreshTable()
          this.closeModal();
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.showModal = false;
      this.$emit("closeModal");
    },
    refreshTable() {
      this.$emit("refreshTable");
    },
    async getItemDetails() {
      try {
        const data = await InventoryService.getItemDetails({
          item_detail_id: this.itemDetailId,
        });
        this.salvageValue = data[0].salvage_value;
        this.lifeTimeValue = data[0].life_time;
        this.specifications = JSON.parse(data[0].predefined);
        this.price = data[0].price;
        this.currency = data[0].currency;
        this.exchange = data[0].currency_amount ?? 0;
      } catch (err) {
        console.error(err);
      }
    },
    itemName(value){
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    }
  },
};
</script>
<template>
  <b-modal
    v-model="isActive"
    :size="'xmd'"
    title="Input / Output"
    hide-footer
    scrollable
    @hidden="hidden"
  >
    <b-tabs
      pills
      class="mt-1"
    >
      <b-tab
        title="Input"
        active
      >
        <b-card class="border-top-primary border-3 border-table-radius px-0 mt-n1">
          <input-output-table :item-input-output-id="itemId" />
        </b-card>
      </b-tab>
      <b-tab title="Output">
        <b-card class="border-top-primary border-3 border-table-radius px-0 mt-n1">
          <output-table :item-id="itemId" />
        </b-card>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import InputOutputTable from '@/views/logistic/views/inventory2/components/InputOutputTable.vue';
import OutputTable from '@/views/logistic/views/inventory2/components/OutputTable.vue';

export default {
  components: {
    InputOutputTable,
    OutputTable,
  },
  props: {
    itemId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tabModel: 1,
    };
  },
  async created() {
    this.isActive = true;
    await this.getItemsCategory();
  },
  mounted() {},
  methods: {
    hidden() {
      this.isActive = false;
      this.$emit('close');
    },
  },
};
</script>

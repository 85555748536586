<template>
  <b-modal
    ref="inventory_detailed_by_item"
    v-model="isActive"
    :size="'xmd'"
    title="INPUT DETAILED"
    hide-footer
    no-close-on-backdrop
    scrollable
    @hidden="hidden"
  >
    <div>
      <b-table
        slot="table"
        ref="grid_inventory_detailed_by_item"
        no-provider-filtering
        :items="myProvider"
        :fields="fields"
        primary-key="id"
        table-class="text-nowrap"
        show-empty
        sticky-header="70vh"
        :busy.sync="isBusy"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(specifications)="data">
          <span v-if="isEmptyObject(data.item.specifications)">No specifications defined</span>
          <div v-else>
            <div
              v-for="(value, key) in data.item.specifications"
              class="mr-4"
            >
              <ul class="list-unstyled">
                <ol class="no-padding-list">
                  {{
                    key.toUpperCase()
                  }}:
                  {{
                    value
                  }}
                </ol>
              </ul>
            </div>
          </div>
        </template>
        <template #cell(availability_status)="data">
          <div>
            <b-badge
              :variant="availabilityStatus(data.item.availability_status)"
              pill
            >
              {{ data.item.availability_status }}
            </b-badge>
          </div>
        </template>
        <template #cell(item_price)="data">
          <div>
            {{ data.item.currency }}  {{ data.item.item_price | currency }}
          </div>
        </template>
        <template #cell(currency_amount)="data">
          <div>
            S/.  {{ data.item.currency_amount | currency }}
          </div>
        </template>
        <template #cell(salvage_value)="data">
            <div>
              {{ data.item.currency }}  {{ data.item.salvage_value | currency }}
            </div>
          </template>
        <template #cell(condition_status)="data">
          <div>
            <b-badge
              pill
              :variant="statusCondition(data.item.condition_status)"
              class="px-2"
            >
              {{ data.item.condition_status }}
            </b-badge>
          </div>
        </template>
        <template #cell(created_at)="data">
            <span>{{ data.item.created_at | myGlobalWithHour }}</span>
          </template>
        <template #cell(actions)="data">
          <div class="text-center">
            <feather-icon
              icon="EyeIcon"
              class="text-primary cursor-pointer mr-1"
              size="20"
              @click="openEditItemDetailsOn(data.item.item_detail_id)"
            />
          </div>
        </template>
      </b-table>
    </div>
    <edit-item-details
      v-if="editItemDetailsOn"
      :item-detail-id="itemDetailId"
      :disabled-all="true"
      @closeModal="closeEditItemDetails"
    />
  </b-modal>
</template>

<script>
import InventoryService from '@/views/logistic/views/inventory2/services/logistic-inventory.service';
import EditItemDetails from '@/views/logistic/views/inventory2/modals/EditItemDetails.vue';
import Fields from '@/views/logistic/views/inventory2/data/input-detailed.data.fields';

export default {
  components: {
    EditItemDetails,
  },
  props: {
    purchaseOrderId: {
      type: Number,
      default: null,
    },
    itemId: {
      type: Number,
      default: null,
    },
    itemDetailIdFromTable: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      editItemDetailsOpnened: false,
      fields: Fields,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      isActive: false,
      isBusy: false,
      itemDetailId: null,
      totalRows: 0,
      startPage: null,
      toPage: null,
      nextPage: null,
      sortBy: '',
      sortDesc: true,
      editItemDetailsOn: false,
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    this.isActive = true;
  },
  methods: {
    hidden() {
      this.isActive = false;
      this.$emit('close');
    },
    async myProvider() {
      const params = {
        purchaseOrderId: this.purchaseOrderId,
        itemId: this.itemId,
        itemDetailId: this.itemDetailIdFromTable,
      };

      try {
        const data = await InventoryService.getDetailedInput(params);
        const items = data.data;
        for (const item of items) {
          const updatedSpecifications = this.getSpecificationsWithNoEmptyValues(item.specifications);
          item.specifications = updatedSpecifications;
        }
        return items;
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
      }
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    },
    openEditItemDetailsOn(itemDetailId) {
      this.itemDetailId = itemDetailId;
      this.editItemDetailsOn = true;
    },
    closeEditItemDetails() {
      this.editItemDetailsOn = false;
    },
    statusCondition(status_condition) {
      switch (status_condition) {
        case 'NEW':
          return 'success';
          break;
        case 'USED':
          return 'primary';
          break;
        case 'REPAIRED':
          return 'warning';
          break;
        case 'DAMAGED':
          return 'danger';
        default:
          break;
      }
    },
    availabilityStatus(availability_status) {
      switch (availability_status) {
        case 'AVAILABLE':
          return 'success';
          break;
        case 'ASSIGNED':
          return 'primary';
          break;
        case 'RESERVED':
          return 'info';
          break;
        case 'REMOVED':
          return 'danger';
          break;
        default:
          break;
      }
    },
    getSpecificationsWithNoEmptyValues(obj) {
      /*
          Remove the empty values from a object, for instance:

          For: {"ram": "", "storage": "", "processor": "Intel"} the output expected is: { "processor": "Intel" }
          For: {"ram": "", "storage": "", "processor": ""} the output expected is: { }
        */
      const myObj = JSON.parse(obj);
      const result = {};
      for (const key in myObj) {
        if (myObj.hasOwnProperty(key) && myObj[key] != '') {
          result[key] = myObj[key];
        }
      }
      return result;
    },
    refreshTable() {
      this.$refs.grid_inventory_detailed_by_item.refresh();
    },
  },
};
</script>

<template>
  <b-modal
    ref="inventory_detailed_by_item"
    :size="'xmd'"
    :title="`${modalTitleFromStockStatus}`"
    title-class="h2 text-center text-white"
    hide-footer
    no-close-on-backdrop
    scrollable
    v-model="isActive"
    @hidden="hidden"
  >
    <div>
      <filter-slot
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :send-multiple-sms="false"
        :change-columns-by-client="true"
        @reload="$refs['grid_inventory_detailed_by_item'].refresh()"
      >
        <b-table
          slot="table"
          ref="grid_inventory_detailed_by_item"
          no-provider-filtering
          :items="myProvider"
          :fields="fields"
          primary-key="id"
          table-class="text-nowrap"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(specifications)="data">
            <span v-if="isEmptyObject(data.item.specifications)"
              >No specifications defined</span
            >
            <div v-else>
              <div
                class="mr-4"
                v-for="(value, key) in data.item.specifications"
              >
                <ul class="list-unstyled">
                  <ol class="no-padding-list">
                    {{
                      key.toUpperCase()
                    }}:
                    {{
                      value
                    }}
                  </ol>
                </ul>
              </div>
            </div>
          </template>

          <template #cell(availability_status)="data">
            <td
              class="align-middle text-center d-flex justify-content-center mt-0 pt-0 align-items-center"
            >
              <availability-app
                :data="data"
                :sendName="dataName"
                @refreshTable="refreshTable"
              />
            </td>
          </template>

          <template #cell(condition_status)="data">
            <td
              class="align-middle text-center d-flex justify-content-center mt-0 pt-0"
            >
              <condition-app
                :data="data"
                :sendName="dataName"
                @refreshTable="refreshTable"
              />
            </td>
          </template>
          <template #cell(item_price)="data">
            <div>
              {{ data.item.currency }} {{ data.item.item_price | currency }}
            </div>
          </template>
          <template #cell(currency_amount)="data">
            <div>S/. {{ data.item.currency_amount | currency }}</div>
          </template>
          <template #cell(salvage_value)="data">
            <div>
              {{ data.item.currency }} {{ data.item.salvage_value | currency }}
            </div>
          </template>
          <template #cell(assigned_to_user_name)="data">
            <div class="d-flex flex-column" v-if="!!data.item.assigned_to_user_name">
              <span>
                {{ data.item.assigned_to_user_name }}
              </span>
              <b-badge
                  variant="info fw-bold pill"
              >
                {{ data.item.assigned_to_module_name }}
              </b-badge>
            </div>
          </template>
          <template #cell(created_at)="data">
            <span>{{ data.item.created_at | myGlobalWithHour }}</span>
          </template>
          <template #cell(actions)="data">
            <div class="text-center">
              <feather-icon
                v-if="
                  statusStockTypeName == 'Available' ||
                  (statusStockTypeName == 'All' &&
                    !['RESERVED', 'REMOVED', 'ASSIGNED'].includes(
                      data.item.availability_status
                    ))
                "
                icon="EditIcon"
                class="text-primary cursor-pointer mr-1"
                size="20"
                @click="openEditItemDetails(data.item.id)"
              />
            </div>
          </template>
        </b-table>
      </filter-slot>
    </div>
    <edit-item-details
      v-if="editItemDetailsOpnened"
      :item-detail-id="itemDetailId"
      @closeModal="closeEditItemDetails"
      @refreshTable="refreshTable"
    />
  </b-modal>
</template>

<script>
import InventoryService from "@/views/logistic/views/inventory2/services/logistic-inventory.service";
import EditItemDetails from "@/views/logistic/views/inventory2/modals/EditItemDetails.vue";
import Fields from "@/views/logistic/views/inventory2/data/stock-detailed.data.fields";
import Filters from "@/views/logistic/views/inventory2/data/stock-detailed.data.filters";
import AvailabilityApp from "@/views/logistic/views/inventory2/others/AvailabilityApp.vue";
import ConditionApp from "@/views/logistic/views/inventory2/others/ConditionApp.vue";

export default {
  components: {
    EditItemDetails,
    AvailabilityApp,
    ConditionApp,
  },
  props: {
    itemTypeId: {
      type: Number,
      required: true,
    },
    statusStockType: {
      type: Number,
      default: 0,
    },
    nameItem: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      editItemDetailsOpnened: false,
      fields: Fields,
      isStatus: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      filters: Filters,
      dataName: "",
      isActive: false,
      isBusy: false,
      itemDetailId: null,
      totalRows: 0,
      startPage: null,
      toPage: null,
      nextPage: null,
      sortBy: "",
      sortDesc: true,
    };
  },
  async created() {
    this.dataName = this.nameItem;
    this.isActive = true;
    await this.getInventoryBrands();
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    statusStockTypeName() {
      switch (this.statusStockType) {
        case 0:
          return "All";
          break;
        case 1:
          return "Available";
          break;
        case 2:
          return "Reserved";
          break;
        case 3:
          return "Not available";
          break;
        default:
          break;
      }
    },
    modalTitleFromStockStatus() {
      switch (this.statusStockType) {
        case 0:
          return `All Items (${this.nameItem})`;
          break;
        case 1:
          return `Available Items (${this.nameItem})`;
          break;
        case 2:
          return `Reserved Items (${this.nameItem})`;
          break;
        case 3:
          return `Not Available Items (${this.nameItem})`;
          break;
        default:
          break;
      }
    },
  },

  methods: {
    hidden() {
      this.isActive = false;
      this.$emit("close");
    },
    async myProvider() {
      this.showModalBirthday = true;

      const params = {
        page: this.paginate.currentPage,
        perpage: this.paginate.perPage,
        item_type_id: this.itemTypeId,
        brand: this.filters[0].model,
        condition: this.filters[1].model,
        search_text: this.filterPrincipal.model,
        status_stock_type: this.statusStockType,
      };

      try {
        const data = await InventoryService.getDetailedInventoryByItem(params);

        this.startPage = data.data.from;
        this.totalRows = data.data.total;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.toPage = data.data.to;

        const items = data.data.data;

        for (const item of items) {
          const updatedSpecifications = this.getSpecificationsWithNoEmptyValues(
            item.specifications
          );
          item.specifications = updatedSpecifications;
        }

        return items;
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
      }
    },
    async getInventoryBrands() {
      try {
        const { data } = await InventoryService.getInventoryBrands();
        this.filters[0].options = [...this.filters[0].options, ...data];
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
      }
    },
    refreshTable() {
      this.$refs.grid_inventory_detailed_by_item.refresh();
    },


    getSpecificationsWithNoEmptyValues(obj) {
      /*
        Remove the empty values from a object, for instance:

        For: {"ram": "", "storage": "", "processor": "Intel"} the output expected is: { "processor": "Intel" }
        For: {"ram": "", "storage": "", "processor": ""} the output expected is: { }
      */
      const myObj = JSON.parse(obj);
      const result = {};
      for (const key in myObj) {
        if (myObj.hasOwnProperty(key) && myObj[key] != "") {
          result[key] = myObj[key];
        }
      }

      return result;
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    },
    refreshTable() {
      this.$refs.grid_inventory_detailed_by_item.refresh();
    },
    openEditItemDetails(item_details_id) {
      this.itemDetailId = item_details_id;
      this.editItemDetailsOpnened = true;
    },
    closeEditItemDetails() {
      this.itemDetailId = null;
      this.editItemDetailsOpnened = false;
    },
  },
};
</script>

<style scoped>
.col-availability {
  width: 100px !important;
}

.table td > td {
  border-top: none;
  padding-left: 0px;
  padding-right: 0px;
  width: 130px;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"size":"lg","title":_vm.disabledAll ? 'Watch' : 'Edit',"hide-footer":_vm.disabledAll,"title-tag":"h3"},on:{"hidden":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{staticClass:"px-1",attrs:{"variant":"danger"},on:{"click":function($event){return cancel()}}},[_vm._v("CANCEL")]),_c('b-button',{staticClass:"px-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveChanges()}}},[_vm._v("SAVE")])]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('ValidationObserver',{ref:"form"},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('ValidationProvider',{attrs:{"rules":"validate-amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Price"}},[_c('money',_vm._b({staticClass:"form-control custom-specified-column",class:errors[0] ? 'border-danger' : '',attrs:{"prefix":_vm.currency+' ',"state":false,"disabled":_vm.disabledAll},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}},'money',_vm.vMoney,false)),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"sm":"3"}},[_c('b-form-group',{attrs:{"label":"Exchange Rate"}},[_c('money',_vm._b({staticClass:"form-control custom-specified-column",attrs:{"prefix":'S/. ',"state":false,"disabled":""},model:{value:(_vm.exchange),callback:function ($$v) {_vm.exchange=$$v},expression:"exchange"}},'money',_vm.vMoney,false))],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|greated-than-zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Life Time (Months)"}},[_c('b-form-input',{staticClass:"input-background-white",class:{ 'border border-danger': errors[0] },attrs:{"disabled":_vm.disabledAll,"type":"number"},model:{value:(_vm.lifeTimeValue),callback:function ($$v) {_vm.lifeTimeValue=$$v},expression:"lifeTimeValue"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|greated-than-zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Salvage Value"}},[_c('money',_vm._b({staticClass:"form-control custom-specified-column w-100",class:errors[0] ? 'border-danger' : '',attrs:{"prefix":_vm.currency + ' ',"state":false,"disabled":_vm.disabledAll},model:{value:(_vm.salvageValue),callback:function ($$v) {_vm.salvageValue=$$v},expression:"salvageValue"}},'money',_vm.vMoney2,false)),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0]))]):_vm._e()],1)]}}])})],1),_vm._l((_vm.specifications),function(item){return _c('b-col',{key:item.id,attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.itemName(item.name)}},[_c('b-form-input',{staticClass:"input-background-white",attrs:{"disabled":_vm.disabledAll},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1)],1)})],2)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <center class="w-100">
       <b-badge
                :variant="availabilityStatus(data.item.availability_status)"
                pill
                class="cursor-pointer mr-1"
                style="width:95px"
                v-if="!isStatus"
                @click="isStatus = true"
              >
                {{ data.item.availability_status }}
        </b-badge>
        <feather-icon 
                v-b-tooltip.hover.top="'Tracking'"
                class="text-primary cursor-pointer"
                icon="ListIcon"
                v-if="!isStatus"
                size="15"
                @click="openModalTracking(data.item.id)"
              />
       <div class="w-100 d-flex justify-content-center align-items-center" v-if="isStatus">
        
           <b-form-select                                      
               :options="options" 
               v-model="actionSelected"
               @change="openModal(data.item.id)"
               style="font-size:10px"
           />
           <div class="ml-1">
            <feather-icon 
                icon="XIcon" 
                @click="cancelStatusAvailabilty" 
                class="text-danger cursor-pointer" 
                size="16" />
           </div>
       </div>


   <div>
     <modal-tracking-availability 
         v-if="openModalTrackingAvailability"
         :sendId = "dataId"
         :sendItem = "arrItem"
         @close="closeModalTrackingAvailability"
     />
   </div>
    </center>
</template>
<script>
import InventoryService from "@/views/logistic/views/inventory2/services/logistic-inventory.service";
import ModalTrackingAvailability from "@/views/logistic/views/inventory2/modals/TrackingAvailability.vue";
import { mapGetters } from "vuex";

export default {
    components:{
        ModalTrackingAvailability
    },

   props:{
    data:{
        type:Object, 
        required:true
    },
    sendName:{
        type:String,
        default:''
    }
},
   data(){
    return {
       activeModal:false,
       openModalTrackingAvailability:false,
       dataId:0,
       statusPending:{
           id:0, value:false
       } ,
       actionSelected:'',
       arrItem:[],
       isStatus : false,
        options : []
    
        }
    },

    created(){
        this.getListStatus();
        this.arrItem.push({
            name:this.sendName,
            sku:this.data.item.sku_code
        });
    },
    computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    },
   methods:{
    closeModalTrackingAvailability(){
        this.openModalTrackingAvailability = false
    },
    openModalTracking(id){
        this.dataId = id;
        this.openModalTrackingAvailability = true;
    },

    getListStatus(){
        this.actionSelected = this.data.item.availability_status;
        const status = this.data.item.availability_status;

        switch (status) {
            case 'AVAILABLE':
            case 'REMOVED' :
                this.options = [
                { value: 'AVAILABLE', text: "AVAILABLE" },
                { value: 'REMOVED', text: "REMOVED" }
             ]
                break;
            case 'RESERVED':
                this.options = [
                    { value: 'RESERVED', text: "RESERVED" },
                    { value: 'AVAILABLE', text: "AVAILABLE" },
                    { value: 'REMOVED', text: "REMOVED" }
                ]
                break;
            case 'ASSIGNED':
            this.options = [
                { value: 'AVAILABLE', text: "AVAILABLE" },
                { value: 'ASSIGNED', text: "ASIGNED" },
                { value: 'REMOVED', text: "REMOVED" }
            ]
            break;
        
            default:

                break;
        }
    },

    availabilityStatus(availability_status) {
      switch (availability_status) {
        case "AVAILABLE":
          return "light-success";
          break;
        case "ASSIGNED":
          return "light-primary";
          break;
        case "RESERVED":
          return "light-info";
          break;
        case "REMOVED":
          return "light-danger";
          break;
        default:
          break;
      }
    },

    cancelStatusAvailabilty(){
        this.isStatus = false;
    },

       refreshTable(){
           this.statusPending.value = false
           this.$emit('refreshTable')
       },
      async openModal(id){

        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
            const data = await InventoryService.updateAvailabilityStatus({
                item_detail_id : id,
                status: this.actionSelected,
                created_by: this.currentUser.user_id
            })

            if(data.status === 200){
                this.showGenericSuccessSwal({});
                this.isStatus = false;
                this.$emit('refreshTable');
            }
        }else{
            this.actionSelected = this.data.item.availability_status;
        }

       }
   },
}
</script>
export default [
  {
    key: 'item_name',
    label: 'Name',
    visible: true,
  },
  {
    key: 'category_name',
    label: 'Category',
    class: 'text-center',
    visible: true,
  },
  {
    key: 'subcategory_name',
    label: 'Sub-category name',
    class: 'text-center',
    visible: true,
  },
  {
    key: 'status_stock',
    label: 'Stock Status',
    class: 'text-center',
    visible: true,
  },
  {
    key: 'current_stock',
    label: 'Current Stock',
    class: 'text-center',
    visible: true,
  },
  {
    key: 'reserved_stock',
    label: 'Reserved Stock',
    class: 'text-center',
    visible: true,
  },
  {
    key: 'not_available_stock',
    label: 'No Available Stock',
    class: 'text-center',
    visible: true,
  },
  {
    key: 'minimum_stock',
    label: 'Minimum Stock',
    class: 'text-center',
    visible: true,
  },
  {
    key: 'initial_stock',
    label: 'initial Stock',
    class: 'text-center',
    visible: true,
  },
  {
    key: 'sku_code',
    label: 'SKU',
    class: 'text-center',
    visible: false,
  },
  {
    key: 'details',
    label: 'Details',
    class: 'text-center',
    visible: true,
  },
  {
    key: 'input_output',
    label: 'Input/Output',
    class: 'text-center',
    visible: true,
  },
  {
    key: 'actions',
    label: 'Actions',
    class: 'text-center',
    visible: true,
  }
];

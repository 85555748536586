<template>
    <b-modal
      v-model="onControl"
      header-bg-variant="primary"
      title-class="h3 text-white font-weight-bolder"
      size="md"
      :title=titleTracking
      modal-class="modal-primary"
      hide-footer
      scrollable
      @hidden="close"
    >
      <filter-slot
        :no-visible-principal-filter="true"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :filter="[]"
        :filter-principal="{}"
        @reload="$refs['refTrackingTable'].refresh()"
      >
        <template #table>
          <b-table
            ref="refTrackingTable"
            no-border-collapse
            class="position-relative"
            :fields="fields"
            show-empty
            no-provider-filtering
            sticky-header="50vh"
            primary-key="id"
            responsive="sm"
            :items="searchConditionStatus"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :filter="searchInput"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2 ">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
  
            <template #head(status)="data">
              <div class="text-center pt-header">
                {{ data.label }}
              </div>
            </template>

            <template #head(created_by)="data">
              <div class="text-center  ">
                {{ data.label }}
              </div>
            </template>

            <template #head(updated_by)="data">
              <div class="text-center  ">
                {{ data.label }}
              </div>
            </template>
           
            <template #cell(status)="data">
              <td class="align-middle text-center d-flex justify-content-center td-padding">
                <b-badge
                :variant="conditionStatus(data.item.status)"
                pill
                class="cursor-pointer mr-1"
                style="width:95px"
              >
              {{ data.item.status }}
               </b-badge>
              </td>
            </template>         
  
            <template
              #cell(created_by)="data"
            >
              <td class="align-middle text-center d-flex justify-content-center  align-items-center m-0 py-0">
                {{ data.item.created_by }} <br> {{ data.item.created_at | myGlobalWithHour }}
              </td>
            </template>

            <template
              #cell(updated_by)="data"
            >
              <td class="align-middle text-center d-flex justify-content-center  align-items-center m-0 py-0">
                {{ data.item.updated_by }} <br> {{ data.item.updated_at | myGlobalWithHour }}
              </td>
            </template>
  
          </b-table>
        </template>
      </filter-slot>
    </b-modal>
  </template>
  
  <script>
  
  import InventoryService from "@/views/logistic/views/inventory2/services/logistic-inventory.service";

  export default {
    props: {
        sendId: {
        type: Number,
        default: null,
      },
      sendItem:{
        type:Array,
        default:[]
      }
    },
  
    data() {
      return {
        onControl: false,
        startPage: 0,
        toPage: 0,
        isBusy: false,
        sortBy: 'name',
        titleTracking:'',
        sortDesc: true,
        searchInput: '',
        fields: [
          {
            key: 'status',
            sortable: false,
            label: 'Status',
            class: 'px-1 pt-0 pb-0',
          },
          {
            key: 'created_by',
            sortable: false,
            label: 'Created by',
           
          },
          {
            key: 'updated_by',
            sortable: false,
            label: 'Updated by',
           
          }
        ],
        trackingItems: [],
        totalRows: 1,
        paginate: {
          perPage: 10,
          currentPage: 1,
        },
  
      };
    },
  
    async created() {
      this.onControl = true;
      this.titleTracking = `Tracking (${ this.sendItem[0].name }- ${ this.sendItem[0].sku })`;
    },
  
    methods: {
        conditionStatus(condition_status) {
      switch (condition_status) {
        case "NEW":
          return "light-success";
          break;
        case "USED":
          return "light-primary";
          break;
        case "REPAIRED":
          return "light-warning";
          break;
        case "DAMAGED":
          return "light-danger";
          break;
        default:
        return "light-secondary";
          break;
      }
    },

      close() {
        this.$emit('close');
      },

      async searchConditionStatus(ctx) {
        const response = await InventoryService.getConditionStatusTracking({
          item_detail_id: this.sendId,
          orderby: 'created_at',
          page: ctx.currentPage,
          order: ctx.sortDesc ? 'desc' : 'asc',
          perPage: ctx.perPage,
        });
        
        this.trackingItems = response.data;
        this.startPage = response.from;
        this.paginate.currentPage = response.current_page;
        this.paginate.perPage = response.per_page;
        this.totalRows = response.total;
        this.toPage = response.to;
        return this.trackingItems || [];
      },
  
    },
  
  };
  </script>
  <style >
   .dark-layout .table td td{
    border-color: transparent !important;
    padding-right: 0;
    /* align-items: middle; */
  }
 
  .style-money{
    padding-right: 3.2px;
  }

  .td-padding{
    padding-top:20px !important ;
  }
  .pt-header{
    padding-top: 9.720px !important ;
  }
  
  .table td>td {
      border-top: none;
      padding-left:  0px;
      padding-right: 0px;
      padding-top: 0;
    }
  </style>
  
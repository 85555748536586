export default [
  {
    type: 'select',
    label: 'Category',
    margin: true,
    showLabel: true,
    options: [],
    model: null,
    reduce: 'id',
    selectText: 'name',
    cols: 12,
    md: 2,
    visible: true,
  },
  {
    type: 'select',
    label: 'Sub-category',
    margin: true,
    showLabel: true,
    options: [],
    model: null,
    reduce: 'id',
    selectText: 'name',
    cols: 12,
    md: 2,
    visible: false,
  },
  {
    type: 'select',
    label: 'Stock Status',
    margin: true,
    showLabel: true,
    options: [
      { 'id': 1, 'name': 'Out of stock' },
      { 'id': 2, 'name': 'Low stock' },
      { 'id': 3, 'name': 'In stock' },
    ],
    model: null,
    reduce: 'id',
    selectText: 'name',
    cols: 12,
    md: 2,
    visible: true,
  },
];

<template>
    <center class="w-100">
       <b-badge
                :variant="conditionStatus(data.item.condition_status)"
                pill
                class="cursor-pointer mr-1"
                style="width:95px"
                v-if="!isStatus"
                @click="isStatus = true"
              >
                {{ data.item.condition_status }}
        </b-badge>
        <feather-icon 
                v-b-tooltip.hover.top="'Tracking'"
                class="text-primary cursor-pointer"
                icon="ListIcon"
                v-if="!isStatus"
                size="15"
                @click="openModalTracking(data.item.id)"
          />

       <div class="w-100 d-flex justify-content-center align-items-center" v-if="isStatus">
        
           <b-form-select                                      
               :options="options" 
               v-model="actionSelected"
               @change="openModal(data.item.id)"
               style="font-size:10px"
           />
           <div class="ml-1">
            <feather-icon 
                icon="XIcon" 
                @click="cancelStatusCondition" 
                class="text-danger cursor-pointer" 
                size="16" />
           </div>
       </div>
       <modal-tracking-condition
        v-if="openModalTrackingCondition"
         :sendId = "dataId"
         :sendItem = "arrItem"
         @close="closeModalTrackingCondition"
       />
    </center>
</template>
<script>
import InventoryService from "@/views/logistic/views/inventory2/services/logistic-inventory.service";
import ModalTrackingCondition from "@/views/logistic/views/inventory2/modals/TrackingCondition.vue"
import { mapGetters } from "vuex";

export default {
  components:{
      ModalTrackingCondition
    },
   props:{
    data:{
        type:Object, 
        required:true
    },
    sendName:{
        type:String,
        default:''
    }
},
   data(){
    return {
       activeModal:false,
       statusPending:{
           id:0, value:false
       } ,
       actionSelected:'',
       arrItem:[],
       openModalTrackingCondition:false,
       isStatus : false,
        options : []
    
        }
    },

    created(){
        this.getListStatus();
        this.arrItem.push({
            name:this.sendName,
            sku:this.data.item.sku_code
        })
    },
    computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    },
   methods:{

    getListStatus(){
        this.actionSelected = this.data.item.condition_status;
        const status = this.data.item.condition_status;

        this.options = [
            { value: 'NEW', text: "NEW" },
            { value: 'USED', text: "USED" },
            { value: 'REPAIRED', text: "REPAIRED" },
            { value: 'DAMAGED', text: "DAMAGED" },
        ]
    },

    openModalTracking(id){
        this.dataId = id;
        this.openModalTrackingCondition = true;
    },

    conditionStatus(availability_status) {
      switch (availability_status) {
        case "NEW":
          return "light-success";
          break;
        case "USED":
          return "light-warning";
          break;
        case "REPAIRED":
          return "light-info";
          break;
        case "DAMAGED":
          return "light-danger";
          break;
        default:
          break;
      }
    },
    closeModalTrackingCondition(){
      this.openModalTrackingCondition = false;
    },

    cancelStatusCondition(){
        this.isStatus = false;
    },

       refreshTable(){
           this.statusPending.value = false
           this.$emit('refreshTable')
       },
      async openModal(id){
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
            const data = await InventoryService.updateConditionStatus({
                item_detail_id : id,
                status: this.actionSelected,
                created_by: this.currentUser.user_id
            })

            if(data.status === 200){
                this.showGenericSuccessSwal({});
                this.isStatus = false;
                this.$emit('refreshTable');
            }
        }else{
            this.actionSelected = this.data.item.condition_status;
        }

       }
   }
}
</script>
<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :send-multiple-sms="false"
      @reload="$refs['refStockList'].refresh()"
    >
      <b-table
        slot="table"
        ref="refStockList"
        no-provider-filtering
        :items="myProvider"
        :fields="filteredFields"
        primary-key="id"
        table-class="text-nowrap"
        show-empty
        small
        sticky-header="70vh"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(item_name)="data">
          {{ data.item.item_name }}
        </template>
        <template #cell(category_name)="data">
          {{ data.item.category_name }}
        </template>
        <template #cell(subcategory_name)="data">
          {{ data.item.subcategory_name }}
        </template>
        <template #cell(status_stock)="data">
          <b-badge
            class="px-1"
            pill
            color="light-primary"
            :variant="bgColorByStockStatus(data.item.stock_status)"
          >
            {{ messageByStockStatus(data.item.stock_status) }}
          </b-badge>
        </template>
        <template #cell(current_stock)="data">
          <span
            v-if="stockHasDetails(data.item.is_detailed)"
            class="text-center cursor-pointer"
            :style="{
              letterSpacing: '1px',
              fontSize: '12px',
              fontWeight: 'bold',
              display: 'inline-block',
              width: '25px',
              height: '25px',
              borderRadius: '50%',
              backgroundColor: '#1974d2',
              color: '#FFFFFF',
              textAlign: 'center',
              lineHeight: '25px',
              boxShadow: '0px 3px 5px 3px rgba(0, 0, 0, 0.2)',
            }"
            @click="openInventoryDetailedByItem(data.item.item_type_id,data.item.item_name ,1)"
          >{{ data.item.current_stock }}
          </span>
          <span
            v-else
            class="text-center"
            :style="{
              letterSpacing: '1px',
              fontSize: '12px',
              paddingTop: '4px',
            }"
          >{{ data.item.current_stock }}</span>
        </template>
        <template #cell(reserved_stock)="data">
          <span
            v-if="stockHasDetails(data.item.is_detailed)"
            class="text-center cursor-pointer"
            :style="{
              letterSpacing: '1px',
              fontSize: '12px',
              fontWeight: 'bold',
              display: 'inline-block',
              width: '25px',
              height: '25px',
              borderRadius: '50%',
              backgroundColor: '#1974d2',
              color: '#FFFFFF',
              textAlign: 'center',
              lineHeight: '25px',
              boxShadow: '0px 3px 5px 3px rgba(0, 0, 0, 0.2)',
            }"
            @click="openInventoryDetailedByItem(data.item.item_type_id,data.item.item_name ,2)"
          >{{ data.item.reserved_stock }}
          </span>
          <span
            v-else
            class="text-center"
            :style="{
              letterSpacing: '1px',
              fontSize: '12px',
              paddingTop: '4px',
            }"
          >{{ data.item.reserved_stock }}</span>
        </template>
        <template #cell(not_available_stock)="data">
          <span
            v-if="stockHasDetails(data.item.is_detailed)"
            class="text-center cursor-pointer"
            :style="{
              letterSpacing: '1px',
              fontSize: '12px',
              fontWeight: 'bold',
              display: 'inline-block',
              width: '25px',
              height: '25px',
              borderRadius: '50%',
              backgroundColor: '#1974d2',
              color: '#FFFFFF',
              textAlign: 'center',
              lineHeight: '25px',
              boxShadow: '0px 3px 5px 3px rgba(0, 0, 0, 0.2)',
            }"
            @click="openInventoryDetailedByItem(data.item.item_type_id,data.item.item_name, 3)"
          >{{ data.item.not_available_stock }}
          </span>
          <span
            v-else
            class="text-center"
            :style="{
              letterSpacing: '1px',
              fontSize: '12px',
              paddingTop: '4px',
            }"
          >{{ data.item.not_available_stock }}</span>
        </template>
        <template #cell(minimum_stock)="data">
          <span class="text-center">{{ data.item.minimum_stock }}</span>
        </template>
        <template #cell(sku_code)="data">
          {{ data.item.sku_code.toUpperCase() }}
        </template>
        <template #cell(details)="data">
          <div v-if="stockHasDetails(data.item.is_detailed)">
            <feather-icon
              class="cursor-pointer text-info"
              icon="InfoIcon"
              size="20"
              @click="openInventoryDetailedByItem(data.item.item_type_id,data.item.item_name, 0)"
            />
          </div>
        </template>
        <template #cell(input_output)="data">
          <div class="text-center">
            <tabler-icon
              icon="ArrowsSortIcon"
              size="20"
              class="text-success ml-1 cursor-pointer"
              @click="openInputOutputModal(data.item.item_type_id)"
            />
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="text-center">
            <feather-icon
              icon="EditIcon"
              size="20"
              class="text-warning ml-1 cursor-pointer"
              @click="itemModal(data.item)"
            />

            <!-- User can not delete the laptop item (data.item.item_type_id !== 1), the desktop computer item (data.item.item_type_id !== 2), also, cannot delete the items in which is_detailed = 1, finally, user can not delete in case there are already reserved stock about this item (data.item.reserved_stock <= 0) -->
            <feather-icon
              v-if="data.item.item_type_id !== 1 && data.item.item_type_id !== 2 && data.item.is_detailed !== 1 && data.item.reserved_stock <= 0"
              icon="TrashIcon"
              size="20"
              class="text-danger ml-1 cursor-pointer"
              @click="deleteItemInventory(data.item)"
            />

          </div>
        </template>
      </b-table>
    </filter-slot>
    <inventory-detailed-by-item
      v-if="inventoryDetailedByItemOpened"
      :item-type-id="currentItemTypeId"
      :status-stock-type="statusStockType"
      :name-item="nameItem"
      @close="closeInventoryDetailedByItem"
    />
    <data-item-modal
      v-if="showItemModal"
      :type-modal="'EDIT'"
      :item="itemSelected"
      @closeModal="showItemModal = false"
      @refreshTable="refreshTable"
    />
    <input-output-modal
      v-if="inputOutputModalOn"
      :item-id="itemId"
      @close="closeInputOutputModal"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Fields from '@/views/logistic/views/inventory2/data/stock.data.fields';
import Filters from '@/views/logistic/views/inventory2/data/stock.data.filters';
import InventoryService from '@/views/logistic/views/inventory2/services/logistic-inventory.service';
import InventoryDetailedByItem from '@/views/logistic/views/inventory2/modals/InventoryDetailedByItem.vue';
import DataItemModal from '@/views/logistic/views/inventory2/modals/dataItemModal.vue';
import ModalRegisterExistentItems from '@/views/logistic/views/inventory2/modals/ModalRegisterExistentItems.vue';
import InputOutputModal from '@/views/logistic/views/inventory2/modals/InputOutputModal.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    vSelect,
    InventoryDetailedByItem,
    DataItemModal,
    InputOutputModal,
  },
  props: {},
  data() {
    return {
      currentItemTypeId: null,
      fields: Fields,
      filters: Filters,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Description...',
        model: '',
      },
      isBusy: false,
      inventoryDetailedByItemOpened: false,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: null,
      nextPage: null,
      toPage: null,
      sortBy: '',
      nameItem: '',
      sortDesc: true,
      statusStockType: 0,
      totalRows: 0,
      showItemModal: false,
      itemSelected: [],
      inputOutputModalOn: false,
      itemId: null,
      showModalRegisterExistentItems: false,
    };
  },
  async created() {
    await this.getItemsCategory();
  },
  computed: {
    ...mapGetters({
      getRefreshTable: 'InventoryLogistic/getRefreshTable',
    }),
    filterCategoryValue() {
      return this.filters[0].model;
    },
    filteredFields() {
      return this.fields.filter(field => field.visible);
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: 'InventoryLogistic/CHANGE_REFRESH_TABLE',
    }),
    async myProvider() {
      const params = {
        page: this.paginate.currentPage,
        perpage: this.paginate.perPage,
        category_id: this.filters[0].model,
        subcategory_id: this.filters[1].model,
        search_text: this.filterPrincipal.model,
        status_stock: this.filters[2].model,
      };

      const data = await InventoryService.getCurrentInventoryStock(params);
      this.startPage = data.data.from;
      this.totalRows = data.data.total;
      this.paginate.currentPage = data.data.current_page;
      this.paginate.perPage = data.data.per_page;
      this.nextPage = this.startPage + 1;
      this.toPage = data.data.to;

      return data.data.data;
    },
    async getItemsCategory() {
      const { data } = await InventoryService.getItemsCategory();
      this.filters[0].options = data;
    },
    async getItemsSubCategory(categoryId) {
      const { data } = await InventoryService.getItemsSubcategory({
        category_id: categoryId,
      });
      this.filters[1].options = data;
    },
    openInventoryDetailedByItem(itemTypeId, nameItem, statusStockType) {
      this.statusStockType = statusStockType;
      this.currentItemTypeId = itemTypeId;
      this.nameItem = nameItem;
      this.inventoryDetailedByItemOpened = true;
    },
    closeInventoryDetailedByItem() {
      this.statusStockType = 0;
      this.currentItemTypeId = null;
      this.nameItem = '';
      this.inventoryDetailedByItemOpened = false;
    },
    openInputOutputModal(itemId) {
      this.inputOutputModalOn = true;
      this.itemId = itemId;
    },
    closeInputOutputModal() {
      this.inputOutputModalOn = false;
    },

    itemModal(data) {
      this.itemSelected = data;
      this.showItemModal = true;
    },
    refreshTable() {
      this.$refs.refStockList.refresh();
    },
    messageByStockStatus(stock_status) {
      switch (stock_status) {
        case 1:
          return 'Out of stock';
          break;
        case 2:
          return 'Low Stock';
          break;
        case 3:
          return 'In Stock';
          break;
        default:
          break;
      }
    },
    bgColorByStockStatus(stock_status) {
      switch (stock_status) {
        case 1:
          return 'danger';
          break;
        case 2:
          return 'warning';
          break;
        case 3:
          return 'success';
          break;
        default:
          break;
      }
    },
    stockHasDetails(is_detailed) {
      return is_detailed == 1;
    },

    async deleteItemInventory(item) {
      try {
        const result = await this.showConfirmSwal(
          'Are you sure you want to delete this item?'
        );
        if (result.isConfirmed) {
          const data = await InventoryService.deleteInventoryItem({
            item_id: item.item_type_id,
          });
          if (data.status === 200) {
            this.showSuccessSwal();
            this.refreshTable();
          }
        }
      } catch (error) {
        this.showErrorSwal(error)
      }
    },

  },
  watch: {
    async filterCategoryValue(newCategoryId) {
      if (newCategoryId) {
        this.filters[1].model = null;
        await this.getItemsSubCategory(newCategoryId);
        this.filters[1].visible = true;
        return;
      }
      this.filters[1].options = [];
      this.filters[1].model = null;
      this.filters[1].visible = false;
    },
    getRefreshTable(value) {
      if (value) {
        this.refreshTable();
        this.CHANGE_REFRESH_TABLE(false);
      }
    },
  },
};
</script>
  <style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
td.div {
  width: 100% !important;
}
@media (max-width: 1024) {
  .per-page-datepicker {
    width: 100px;
  }
  .column-table {
    display: flex;
    flex-direction: column;
  }
  .button-top {
    margin-bottom: 22px;
  }
}
@media (max-width: 740px) {
  .per-page-datepicker {
    width: 110px;
  }
  .button-top {
    margin-bottom: 22px;
  }
}
.b-calendar-grid-caption {
  background: transparent !important;
}
.padding {
  padding: 6px;
  width: 80%;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>

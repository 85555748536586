export default [
    {
      key: 'sku_code',
      label: 'SKU Code',
      class: 'text-center',
      visible: true,
    },
    {
      key: 'brand',
      label: 'Brand',
      class: 'text-center',
      visible: true,
    },
    {
      key: 'availability_status',
      label: 'Availability',
      visible: true,
    },
    {
      key: 'condition_status',
      label: 'Condition',
      visible: true,
      class: 'text-center',
    },
    {
      key: 'item_price',
      label: 'Price',
      visible: true,
      class: 'text-center',
    },
    {
        key: 'currency_amount',
        label: 'Exchange rate',
        visible: true,
        class: 'text-center',
    },
    {
      key: 'salvage_value',
      label: 'Salvage value',
      visible: true,
      class: 'text-center',
    },
    {
      key: 'specifications',
      label: 'Specifications',
      class: 'text-center',
      visible: true,
    },
    {
      class: 'text-center',
      key: 'created_at',
      label: 'Created at',
      visible: true,
    },
    {
      key: 'actions',
      label: 'Actions',
      class: 'text-center',
    }
  ];
  